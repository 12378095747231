import React, { useState, useMemo, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'
import * as classes from './Notification.module.css'
import Icon from '../Icon'

let id = 0

export default function Notification({
  config = { tension: 125, friction: 20, precision: 0.1 },
  timeout = 6000,
  children,
  isDisent,
}) {
  const refMap = useMemo(() => new WeakMap(), [])
  const cancelMap = useMemo(() => new WeakMap(), [])
  const [items, setItems] = useState([])

  const transitions = useTransition(items, {
    from: { opacity: 0, height: 0, life: '100%' },
    keys: (item) => item.key,
    enter: (item) => async (next, cancel) => {
      cancelMap.set(item, cancel)
      await next({ opacity: 1, height: refMap.get(item).offsetHeight })
      await next({ life: '0%' })
    },
    leave: [{ opacity: 0 }, { height: 0 }],
    onRest: (result, ctrl, item) => {
      setItems((state) =>
        state.filter((i) => {
          return i.key !== item.key
        })
      )
    },
    config: (item, index, phase) => (key) =>
      phase === 'enter' && key === 'life' ? { duration: timeout } : config,
  })

  useEffect(() => {
    children((msg) => {
      setItems((state) => [...state, { key: id++, msg }])
    })
  }, [])

  return transitions(({ life, ...style }, item) => {
    if (items.length > 2) {
      cancelMap.has(items[0]) && life.get() !== '0%' && cancelMap.get(items[0])()
    }
    return (
      <animated.div style={style} className={classes.message}>
        <div ref={(ref) => ref && refMap.set(item, ref)} className={classes.content}>
          <animated.div
            style={{
              right: life,
              backgroundImage: isDisent
                ? 'linear-gradient(130deg, #c83708, #f54710)'
                : 'linear-gradient(130deg, #5c92cb, #1166cb)',
            }}
            className={classes.life}
          />
          {item.msg}
          <button
            className={classes.button}
            onClick={(e) => {
              e.stopPropagation()
              if (cancelMap.has(item) && life.get() !== '0%') cancelMap.get(item)()
            }}
          >
            <Icon name="close" width="15" height="15" className="fill-current" />
          </button>
        </div>
      </animated.div>
    )
  })
}
