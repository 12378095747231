import React, { useState, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import ContactsMap from '../components/ContactsMap'
import Modal from '../components/Modal'
import ContactForm from '../components/ContactForm'
import Zphone from '../components/Zphone/Zphone'

import Interests from '../components/Interests'

//Notification
import Notification from '../components/Notification'
import * as styles from '../components/Notification/Notification.module.css'

const Contacts = () => {
	const notificationInside = useRef(null)
	const ref = useRef(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const { flagRu } = useStaticQuery(graphql`
		query {
			flagRu: file(relativePath: { eq: "flag-ru.png" }) {
				childImageSharp {
					fixed(width: 14, height: 10) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)

	const showModal = () => setIsModalOpen(true)
	const hideModal = () => setIsModalOpen(false)

	return (
		<Layout
			title="Контакты студии разработки мобильных приложений AppCraft"
			content="✅ Контакты студии разработки мобильных приложений Appcraft. Команда специалистов, гарантия на код, многолетний опыт разработки на заказ."
			keywords="разработка мобильных приложений, заказать мобильное приложение, мобильное приложение на заказ, стоимость мобильного приложения, стартап, продвижение"
			ogSiteName="AppCraft"
			ogTitle="Студия Appcraft | Разработка мобильных приложений для Android и iOS"
			ogDescription="Разработка мобильных продуктов от студии, которая создает и развивает собственные проекты. Эффективные решения для клиентов на iOS и Android, основанные на собственном опыте"
		>
			<div className="contacts">
				<div className="contacts-inner container">
					<div className="contacts-body">
						<h1 className="contacts-title">
							Как с нами <br /> связаться?
						</h1>
						<ul className="contacts-list">
							{/*<li>*/}
							{/*	<p className="mb-5">*/}
							{/*		Для консультации <br /> и заказа приложения*/}
							{/*	</p>*/}
							{/*	<Zphone*/}
							{/*		classes={'zphone link hover:text-primary text-22 sm:text-28 font-bold leading-none'}*/}
							{/*	/>*/}
							{/*</li>*/}

							<li>
								<p className="mb-5">
									Для заказа приложения <br /> и отправки материалов
								</p>
								<a
									className="link hover:text-primary text-22 sm:text-28 font-bold leading-none"
									href="mailto:support@appcraft.pro"
								>
									support@appcraft.pro
								</a>
							</li>

							{/* <li>
                <p className="mb-5">
                  По поводу вакансий <br /> и работы у нас:
                </p>
                <a
                  className="link hover:text-primary text-22 sm:text-28 font-bold leading-none"
                  href="mailto:sa@appcraft.pro"
                >
                  sa@appcraft.pro
                </a>
              </li> */}

							<li>
								<p className="mb-5">
									Пообщаться <br /> с нами в соцсетях:
								</p>
								<div className="flex">
									<a
										className="link hover:text-primary mr-4 text-22 sm:text-28 font-bold leading-none"
										href="https://vk.com/appcraftpro"
										target="_blank"
										rel="noreferrer"
									>
										vk
									</a>
									<a
										className="link hover:text-primary text-22 sm:text-28 font-bold leading-none"
										href="https://www.facebook.com/appcraftpro"
										target="_blank"
										rel="noreferrer"
									>
										facebook
									</a>
								</div>
							</li>
						</ul>

						<div className="contacts-cta">
							<button
								className="contacts__button btn btn--primary w-full sm:w-auto py-4 sm:px-14 sm:py-5 sm:text-22"
								onClick={showModal}
							>
								Оставить заявку
							</button>
							{/* <a
								className="contacts__button btn btn--primary w-full sm:w-auto py-4 sm:px-14 sm:py-5 sm:text-22"
								href="https://qlick.io/ru/widget/appcraft"
								target="_blank"
								rel="nofollow"
							>
								Записаться на консультацию
							</a> */}
							<Modal isOpen={isModalOpen} onClose={hideModal} notificationInside={notificationInside}>
								<div className="px-2">
									<h2 className="mb-6 text-22 sm:text-28 font-bold leading-tight">
										Мы ответим в течение 1 рабочего дня
									</h2>
									<ContactForm Interests={Interests} notificationRef={ref} onClose={hideModal} />
								</div>
							</Modal>
							<div className={styles.notifications} ref={notificationInside}>
								<Notification
									children={(add) => {
										ref.current = add
									}}
								/>
							</div>
						</div>

						<div className="contacts-map">
							<ContactsMap />
						</div>

						<ul className="contacts-address">
							<li>
								<div className="flex items-center mb-2">
									<Img fixed={flagRu.childImageSharp.fixed} className="mr-2" />
									<p className="uppercase text-xs text-gray">Russia</p>
								</div>
								<p> 107140, Москва, <br /> ул. Русаковская, 1–306
								</p>
							</li>
							<li>
								<p>
									390042, Рязань, <br/>ул. К.Маркса, 3, к.2
								</p>
							</li>
							<li>
								<p>
									ООО “ЭппКрафт” <br /> ИНН 6234139760
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Contacts
