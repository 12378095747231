import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import { always, cond, equals } from 'ramda'
import cn from 'classnames'

const getLocationLatLng = cond([
	[equals('moscow'), always({ lat: 55.781983, lng: 37.670134 })],
	[equals('ryazan'), always({ lat: 54.660729997130325, lng: 39.63534530674656 })],
])

const ContactsMap = ({ className }) => {
	const [myMap, setMyMap] = useState(null)
	const [location, setLocation] = useState('ryazan')
	const { lat, lng } = getLocationLatLng(location)

	const createMapOptions = () => ({
		panControl: false,
		zoomControl: false,
		mapTypeControl: false,
		scaleControl: true,
		streetViewControl: false,
		overviewMapControl: false,
		rotateControl: true,
		scrollwheel: false,
		styles: [
			{
				elementType: 'geometry',
			},
			{
				elementType: 'labels.text.fill',
				stylers: [{ color: '#616161' }],
			},
			{
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#f5f5f5' }],
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#bdbdbd' }],
			},
			{
				featureType: 'poi',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi',
				elementType: 'geometry',
				stylers: [{ color: '#eeeeee' }],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#757575' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [{ color: '#e5e5e5' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9e9e9e' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [{ color: '#ffffff' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#b3b3b3' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [{ color: '#dadada' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#616161' }],
			},
			{
				featureType: 'road.local',
				elementType: 'labels',
				// stylers: [{ visibility: 'off' }]
			},
			{
				featureType: 'road.local',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9e9e9e' }],
			},
			{
				featureType: 'transit',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'transit.line',
				elementType: 'geometry',
				stylers: [{ color: '#e5e5e5' }],
			},
			{
				featureType: 'transit.station',
				elementType: 'geometry',
				stylers: [{ color: '#eeeeee' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [{ color: '#c9c9c9' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9e9e9e' }],
			},
		],
	})

	const changeLocation = (name) => {
		const { lat, lng } = getLocationLatLng(name)

		const myLatLng = new window.google.maps.LatLng(lat, lng)
		myMap.panTo(myLatLng)
		setLocation(name)
	}

	const handleApiLoaded = (map, maps) => {
		setMyMap(map)
	}

	const classes = cn('map', className)

	const Marker = () => <div className="map-marker"></div>

	return (
		<div className="map">
			<div className="map-canvas">
				<GoogleMapReact
					bootstrapURLKeys={{ key: 'AIzaSyCmI3bIXUMMqRdNy30TsKQRPd-CM0ayudg' }}
					defaultCenter={{ lat, lng }}
					defaultZoom={18}
					options={createMapOptions}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
				>
					<Marker lat={lat} lng={lng} />
				</GoogleMapReact>
			</div>

			<nav className="map-nav">
				<button
					className={cn('map-link', { 'is-active': location === 'moscow' })}
					onClick={changeLocation.bind(null, 'moscow')}
				>
					Москва
				</button>
				<button
					className={cn('map-link', { 'is-active': location === 'ryazan' })}
					onClick={changeLocation.bind(null, 'ryazan')}
				>
					Рязань
				</button>
			</nav>
		</div>
	)
}

ContactsMap.propTypes = {
	className: PropTypes.string,
}

ContactsMap.defaultProps = {
	className: '',
}

export default ContactsMap
